<template>
  <div class="mainReportBox">
    <div class="topReportBox" style="height: 46px;background-color: #F2F3F5;border-bottom: 1px solid rgb(204, 204, 204)">
      <div class="leftReportTitle">
        报表汇总
      </div>
<!--      <div class="rightReportSidder">-->
<!--        <div style="width: 100%;">-->
<!--          <el-menu :default-active="activeIndex" mode="horizontal" class="menu-list">-->
<!--            <template v-for="item in topSidderData">-->
<!--              <el-menu-item v-if="item.children.length == 0" :index="item.id" :key="item.id" @click="openReport(item)">-->
<!--                <i class="el-icon-menu" style="font-size: 20px; color: #333333"></i>-->
<!--                {{ item.reportCategoryName }}-->
<!--              </el-menu-item>-->
<!--              <el-submenu v-else :key="item.id" :index="item.id">-->
<!--                <template slot="title">-->
<!--                  <div :title="item.reportCategoryName">-->
<!--                    <i class="el-icon-menu" style="font-size: 20px; color: #333333"></i>-->
<!--                    <span slot="title">{{ item.reportCategoryName }}</span>-->
<!--                  </div>-->
<!--                </template>-->
<!--                <el-menu-item v-for="(oneChild,oneIndex) in item.children" :index="oneChild.id" :key="oneIndex"-->
<!--                              @click="openReport(oneChild)" style="background-color: #FFFFFF">-->
<!--                  <template>-->
<!--                    <div class="oneChildNameBox">-->
<!--                      <i class="el-icon-s-order" style="font-size: 20px; color: #333333"></i>-->
<!--                      <span class="oneChildName">{{ oneChild.reportCategoryName }}</span>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </el-menu-item>-->
<!--              </el-submenu>-->
<!--            </template>-->
<!--          </el-menu>-->
<!--        </div>-->
<!--      </div>-->
      <el-button size="medium" icon="el-icon-close" class="closeBtn" @click="occlude">关闭</el-button>
    </div>
<!--    <div class="rightTags">-->
<!--      <el-tabs v-model="activeTag"-->
<!--               type="card"-->
<!--               @tab-click="openTag"-->
<!--               @tab-remove="removeTag">-->
<!--        <el-tab-pane :key="item.id"-->
<!--                     :closable="item.name != 'home'"-->
<!--                     v-for="item in tagList"-->
<!--                     :label="item.reportCategoryName"-->
<!--                     :name="item.reportCategoryName">-->
<!--        </el-tab-pane>-->
<!--      </el-tabs>-->
<!--    </div>-->
    <!--首页-->
    <Home ref="Home" @runApproval="runApproval" v-if="showForm.home"></Home>
    <!--境外项目人员信息-->
    <overseasProjectSummary ref="overseasCode" class="summaryReportBox"
                            v-if="showForm.overseasCode"></overseasProjectSummary>
    <!--境内项目人员信息-->
    <domesticProjectSummary ref="domesticCode" class="summaryReportBox"
                            v-if="showForm.domesticCode"></domesticProjectSummary>
    <!--安全生产月报-->
    <productionSafetySummary ref="safetyProductionMonthly" class="summaryReportBox"
                             v-if="showForm.safetyProductionMonthly"></productionSafetySummary>
    <!--生态环保月报-->
    <productionEcologicalSummary ref="ecologicalProductionMonthly" class="summaryReportBox"
                             v-if="showForm.ecologicalProductionMonthly"></productionEcologicalSummary>
    <!--政府部门安全生产检查及处罚情况-->
    <environmentalSafetyProtectionSummary ref="rptAdminPunishStat" class="summaryReportBox"
                             v-if="showForm.rptAdminPunishStat"></environmentalSafetyProtectionSummary>
    <!--政府部门生态环保检查及处罚情况-->
    <governmentDepartmentSafetyProductionSummary ref="governmentDepartmentSafetyProduction" class="summaryReportBox"
                                          v-if="showForm.governmentDepartmentSafetyProduction"></governmentDepartmentSafetyProductionSummary>
    <!--安全活动统计-->
    <safetActionStatSummary ref="safetActionStat" class="summaryReportBox"
                             v-if="showForm.safetActionStat"></safetActionStatSummary>
    <!--安全生产事故情况-->
    <safeAccidentStatSummary ref="safeAccidentStat" class="summaryReportBox"
                             v-if="showForm.safeAccidentStat"></safeAccidentStatSummary>
    <!--隐患排查治理统计-->
    <hazardInvestigationSummary ref="hazardInvestigation" class="summaryReportBox"
                            v-if="showForm.hazardInvestigation"></hazardInvestigationSummary>
  </div>
</template>
<script>
import overseasProjectSummary from './overseasProjectSummary/index'
import domesticProjectSummary from './domesticProjectSummary/index'
import productionSafetySummary from '@/views/business/reportManager/fillReport/productionSafetySummary'
import productionEcologicalSummary from './productionEcologicalSummary/index'
import environmentalSafetyProtectionSummary from './environmentalSafetyProtectionSummary/index'
import governmentDepartmentSafetyProductionSummary from './governmentDepartmentSafetyProductionSummary/index'
import hazardInvestigationSummary from './hazardInvestigationSummary/index'
import safetActionStatSummary from './safetActionStatSummary/index'
import safeAccidentStatSummary from './safeAccidentStatSummary/index'
import Home from './home/index'
import {mergeListType} from "@/api/reportFixationTasks";
export default {
  components: {
    overseasProjectSummary,
    domesticProjectSummary,
    productionSafetySummary,
    productionEcologicalSummary,
    environmentalSafetyProtectionSummary,
    governmentDepartmentSafetyProductionSummary,
    safeAccidentStatSummary,
    hazardInvestigationSummary,
    safetActionStatSummary,
    Home
  },
  data() {
    return {
      activeIndex: null,
      activeTag: "首页",
      showForm: {
        home:true,
        overseasCode: false,
        domesticCode: false,
        safetyProductionMonthly: false,
        ecologicalProductionMonthly: false,
        rptAdminPunishStat: false,
        governmentDepartmentSafetyProduction:false,
        safetActionStat: false,
        safeAccidentStatSummary: false,
        hazardInvestigation:false,
      },
      tagList: [{
        reportCategoryName: '首页',
        name: 'home',
      }],
      topSidderData: []
    }
  },
  mounted() {
    window.document.title = '报表汇总-中材国际智能安全平台';
    this.getTopTreeData();
    let {reportCode, statDate, fillDeptId, fillDeptName} = this.$route.query;
    if (reportCode != undefined && statDate != undefined && fillDeptId != undefined && fillDeptName != undefined) {
      let row = {
        statDate: statDate,
        fillDeptId: fillDeptId,
        fillDeptName: fillDeptName
      }
      if (reportCode == "overseasCode") {
        this.approvalTag('境外项目人员信息', 'overseasCode')
        this.$nextTick(() => {
          this.$refs.overseasCode.receiveData(row);
        })
      }
      if (reportCode == "domesticCode") {
        this.approvalTag('境内项目人员信息', 'domesticCode')
        this.$nextTick(() => {
          this.$refs.domesticCode.receiveData(row);
        })
      }
      if (reportCode == "safetyProductionMonthly") {
        this.approvalTag('安全生产月报', 'safetyProductionMonthly')
        this.$nextTick(() => {
          this.$refs.safetyProductionMonthly.receiveData(row);
        })
      }
      if (reportCode == "ecologicalProductionMonthly") {
        this.approvalTag('生态环保月报', 'ecologicalProductionMonthly')
        this.$nextTick(() => {
          this.$refs.ecologicalProductionMonthly.receiveData(row);
        })
      }

      if (reportCode == "governmentDepartmentSafetyProduction") {
        this.approvalTag('政府部门生态环保检查及处罚情况', 'governmentDepartmentSafetyProduction')
        this.$nextTick(() => {
          this.$refs.governmentDepartmentSafetyProduction.receiveData(row);
        })
      }

      if (reportCode == "rptAdminPunishStat") {
        this.approvalTag('政府部门安全生产检查及处罚情况', 'rptAdminPunishStat')
        this.$nextTick(() => {
          this.$refs.rptAdminPunishStat.receiveData(row);
        })
      }
      if (reportCode == "safetActionStat") {
        this.approvalTag('安全活动统计', 'safetActionStat')
        this.$nextTick(() => {
          this.$refs.safetActionStat.receiveData(row);
        })
      }
      if (reportCode == "safeAccidentStat") {
        this.approvalTag('安全生产事故情况', 'safeAccidentStat')
        this.$nextTick(() => {
          this.$refs.safeAccidentStat.receiveData(row);
        })
      }
      if (reportCode == "hazardInvestigation") {
        this.approvalTag('隐患排查治理统计', 'hazardInvestigation')
        this.$nextTick(() => {
          this.$refs.hazardInvestigation.receiveData(row);
        })
      }
    }
  },
  methods: {
    runApproval(row) {
      if (row.reportCode == "overseasCode") {
        this.approvalTag('境外项目人员信息','overseasCode')
        this.$nextTick(() => {
          this.$refs.overseasCode.receiveData(row);
        })
      }
      if (row.reportCode == "domesticCode") {
        this.approvalTag('境内项目人员信息','domesticCode')
        this.$nextTick(() => {
          this.$refs.domesticCode.receiveData(row);
        })
      }
      if (row.reportCode == "safetyProductionMonthly") {
        this.approvalTag('安全生产月报','safetyProductionMonthly')
        this.$nextTick(() => {
          this.$refs.safetyProductionMonthly.receiveData(row);
        })
      }
      if (row.reportCode == "ecologicalProductionMonthly") {
        this.approvalTag('生态环保月报','ecologicalProductionMonthly')
        this.$nextTick(() => {
          this.$refs.ecologicalProductionMonthly.receiveData(row);
        })
      }

      if (row.reportCode == "rptAdminPunishStat") {
        this.approvalTag('政府部门安全生产检查及处罚情况','rptAdminPunishStat')
        this.$nextTick(() => {
          this.$refs.rptAdminPunishStat.receiveData(row);
        })
      }

      if (row.reportCode == "governmentDepartmentSafetyProduction") {
        this.approvalTag('政府部门生态环保检查及处罚情况','governmentDepartmentSafetyProduction')
        this.$nextTick(() => {
          this.$refs.governmentDepartmentSafetyProduction.receiveData(row);
        })
      }
      if (row.reportCode == "safetActionStat") {
        this.approvalTag('安全活动统计','safetActionStat')
        this.$nextTick(() => {
          this.$refs.safetActionStat.receiveData(row);
        })
      }
      if (row.reportCode == "safeAccidentStat") {
        this.approvalTag('安全生产事故情况','safeAccidentStat')
        this.$nextTick(() => {
          this.$refs.safeAccidentStat.receiveData(row);
        })
      }
      if (row.reportCode == "hazardInvestigation") {
        this.approvalTag('隐患排查治理统计','hazardInvestigation')
        this.$nextTick(() => {
          this.$refs.hazardInvestigation.receiveData(row);
        })
      }
    },
    // 获取报表顶部导航菜单
    getTopTreeData() {
      mergeListType().then((res) =>{
        if (res.data.code == 200) {
          let data = res.data.data;
          this.replaceData(data);
          this.topSidderData = data;
        }
      })
    },
    replaceData(data) {
      data.forEach((item) => {
        if (item.reportVOList && item.reportVOList.length != 0 && item.children) {
          item.children = item.children.concat(item.reportVOList);
        } else if (item.reportVOList && item.reportVOList.length != 0 && item.children == undefined) {
          item.children = new Array();
          item.children = item.children.concat(item.reportVOList);
        } else {
          item.children = new Array();
        }
        if (item.reportName) {
          item.reportCategoryName = item.reportName;
        }
        if (item.reportCode) {
          item.name = item.reportCode;
        }
        if (item.children && item.children.length != 0) {
          this.replaceData(item.children);
        }
      })
    },
    approvalTag(reportCategoryName,name) {
      this.activeTag = reportCategoryName;
      let dif = this.tagList.filter((item) => {
        if (item.reportCategoryName == reportCategoryName) {
          return item;
        }
      })
      if (dif.length == 0) {
        this.tagList.push({
          reportCategoryName: reportCategoryName,
          name: name,
        })
      }
      for (const key in this.showForm) {
        if (Object.hasOwnProperty.call(this.showForm, key)) {
          this.showForm[key] = false;
          this.showForm[name] = true;
        }
      }
    },
    openReport(node) {
      this.activeTag = node.reportCategoryName;
      let dif = this.tagList.filter((item) => {
        if (item.reportCategoryName == node.reportCategoryName) {
          return item;
        }
      })
      if (dif.length == 0) {
        this.tagList.push(node)
      }
      this.setField(node.name);
    },
    openTag(node) {
      this.activeTag = this.tagList[node.index].reportCategoryName;
      this.setField(this.tagList[node.index].name);
    },
    removeTag(title) {
      this.tagList.forEach((item, index) => {
        if (item.reportCategoryName == title) {
          this.tagList.splice(index, 1);
          if (this.tagList.length > 0) {
            if (title == this.activeTag) {
              this.setField(this.tagList[index - 1].name);
              this.activeTag = this.tagList[index - 1].reportCategoryName;
            }
          }
        }
      })
    },
    setField(fieldName) {
      // 遍历对象的所有字段
      for (const key in this.showForm) {
        if (Object.hasOwnProperty.call(this.showForm, key)) {
          this.showForm[key] = false;
          this.showForm[fieldName] = true;
        }
      }

      if (fieldName != 'home') {
        this.$nextTick(() => {
          this.$refs[fieldName].initData();
        })
      }
    },
    occlude() {
      window.close();
    },
  }
}
</script>
<style>
/*.dark .el-menu--horizontal {*/
/*  background-color: #F2F3F5 !important;*/
/*}*/
.dark .el-menu--horizontal .el-menu {
  background-color: #F2F3F5;
}
.el-menu--horizontal .el-menu--popup{
  box-shadow: unset;
}
.el-popover .el-button--text {
  color: #333333 !important;
}
/*.el-select-dropdown {*/
/*  z-index: 2025 !important;*/
/*}*/
/*.el-message {*/
/*  z-index: 2025 !important;*/
/*}*/
/*.el-picker-panel {*/
/*  z-index: 2025 !important;*/
/*}*/
/*.el-message-box__wrapper {*/
/*  z-index: 2025 !important;*/
/*}*/
/*.v-modal {*/
/*  z-index: 2024 !important;*/
/*}*/
</style>
<style lang="scss" scoped>
.mainReportBox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  background-color: rgb(244, 245, 246);
  width: 100%;
  height: 100%;
}

.topReportBox {
  width: 100%;
  position: relative;
}

.closeBtn {
  position: absolute;
  right: 12px;
  top: 5px;
}

.closeBtn:hover {
  background: #FFF;
  border: 1px solid #DCDFE6;
  color: #606266;
}

.leftReportTitle {
  width: 280px;
  height: 46px;
  text-align: center;
  line-height: 46px;
  color: #333333;
  background-color: #F2F3F5;
  font-size: 20px;
  letter-spacing: 10px;
  border-right: 1px solid rgb(204, 204, 204);
  //border-bottom: 1px solid #E4E7ED;
  //box-shadow: 2px 0 6px rgba(0, 21, 41, .15);
  //border-bottom: 1px solid rgb(204, 204, 204);
  position: absolute;
  z-index: 99;
}

.rightReportSidder {
  width: calc(100% - 391px);
  height: 46px;
  display: flex;
  background-color: #F2F3F5;
  //border-bottom: 1px solid #E4E7ED;
  position: absolute;
  left: 280px;
}
.menu-list{
  //overflow-y: auto;
  display: flex;
  white-space: nowrap;
  width: 100%;
  z-index: 999;
}
::v-deep .common_tree_handle .el-tree {
  height: calc(100vh - 148px) !important;
}

.el-menu {
  background-color: transparent;
}

.rightReportSidder .el-menu-item {
  color: #333333 !important;
  font-size: 16px;
  height: 46px;
  line-height: 46px;
  background-color: #F2F3F5 !important;
  border-bottom: 2px solid #F2F3F5 !important;
}

.el-menu--horizontal > .el-menu-item:hover {
  background-color: unset !important;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  border-bottom: none !important;
  color: #333333 !important;
  font-size: 16px;
  height: 46px;
  line-height: 46px;
  background-color: #F2F3F5;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: #FFFFFF !important;
}

//::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
//  background-color: unset !important;
//}

::v-deep .el-menu .el-submenu__title i:last-child {
  display: none;
}

.el-menu--horizontal .el-menu .el-menu-item {
  height: 46px !important;
  line-height: 46px !important;
  background-color: #FFFFFF !important;
}

.oneChildNameBox {
  height: 46px !important;
  line-height: 46px !important;
  font-size: 14px;
}

.oneChildName {
  color: #333333 !important;
}

.rightTags {
  width: 100%;
  height: 46px;
  background-color: #FFFFFF;
  margin-bottom: 1px;
}

.rightTags .el-tabs--card .el-tabs__header {
  border: none;
}

::v-deep .rightTags .el-tabs__item {
  color: #909399;
  height: 30px;
  line-height: 30px;
  border: 1px solid rgb(204, 204, 204) !important;
  border-radius: 4px;
  margin-right: 8px;
}

::v-deep .rightTags .el-tabs__item.is-active {
  color: #333333;
  background-color: #F2F3F5;
  //border: 1px solid rgb(204, 204, 204) !important;
}

::v-deep .el-tabs--card > .el-tabs__header {
  border-bottom: 1px solid rgb(204, 204, 204);
  height: 30px;
  padding: 8px 16px;
  margin: 0;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: none;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}

::v-deep .el-tabs__content {
  background-color: transparent !important;
}

.reportViewImg {
  width: 80%;
  height: calc(100% - 300px);
  margin: 100px auto;
  display: block;
}

::v-deep .summaryReportBox .common_tree_handle .el-tree {
  height: calc(100vh - 196px) !important;
}
</style>
